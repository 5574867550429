<template>
  <div class="page">
    <BreadCrumb />
    <el-button
      class="goBack"
      plain
      round
      size="mini"
      type="primary"
      @click="goBack"
    >
      返回
    </el-button>
    <div class="page-body">
      <QueryModule
        :query-module="queryModule"
        @queryRes="queryRes"
        @reset="reset"
      >
        <el-col :span="8">
          <el-date-picker
            v-model="queryModule.timeCond"
            end-placeholder="结束日期"
            range-separator="至"
            start-placeholder="开始日期"
            type="daterange"
          />
        </el-col>
        <el-col :span="8">
          <el-form-item label="创建人">
            <el-input v-model="queryModule.createBy" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="作业名称">
            <el-input v-model="queryModule.workName" />
          </el-form-item>
        </el-col>
      </QueryModule>
      <el-table
        v-loading="loading"
        :data="tableData"
        fit
        stripe
      >
        <el-table-column
          :index="(queryModule.page - 1) * pageSize + 1"
          label="序号"
          type="index"
        />
        <el-table-column
          label="作业名称"
          prop="workName"
        />
        <el-table-column
          label="总量/成功/失败/运行"
          prop="Num"
          width="200"
        />

        <el-table-column
          label="创建人"
          prop="createBy"
        />
        <el-table-column
          label="创建时间"
          prop="createTime"
        />
        <el-table-column
          label="修改人"
          prop="updateBy"
        />
        <el-table-column
          label="修改时间"
          prop="updateTime"
        />
        <el-table-column label="操作">
          <template #default="scope">
            <el-tooltip
              class="item"
              content="导出"
              effect="light"
              placement="top-end"
            >
              <el-button
                circle
                size="mini"
                type="primary"
                @click="reportDialog(scope.row)"
              >
                <el-icon :size="15">
                  <i-download />
                </el-icon>
              </el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              content="导出评估报告"
              effect="light"
              placement="top-end"
            >
              <el-button
                circle
                size="mini"
                type="primary"
                @click="evaluationReport(scope.row)"
              >
                <el-icon :size="15">
                  <i-document />
                </el-icon>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-model:currentPage="queryModule.page"
        :page-size="pageSize"
        :total="total"
        background
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
      />

      <el-dialog
        v-model="dialogReport"
        title="导出报告"
      >
        <el-form :model="report">
          <el-form-item label="是否导出未运行成功的作业">
            <el-switch v-model="report.isShowNotSuc" />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button
              type="primary"
              @click="exportWork()"
            >确 定</el-button>
            <el-button @click="dialogReport = false">取 消</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb'
import QueryModule from '/src/components/QueryModule'
import {mapGetters} from 'vuex'




export default {
	components: {
		BreadCrumb,
		QueryModule
	},
	data() {
		return {
			loading: true,
			tableData: '',
			total: 0,
			queryModule: {
				timeCond: null,
				createBy: '',
				workName: '',
				page: 1
			},
			labelWidth: '120',
			dialogReport: false,
			report: {
				workId: '',
				isShowNotSuc: false
			},
			parentPagePara: {
				projectId: ''
			}
		}
	},
	computed: {
		...mapGetters(['pageSize', 'user'])
	},
	mounted: function () {
		this.parentPagePara.projectId = this.$route.query.projectId
		this.workList()
	},
	methods: {
		reset() {
			const _this = this

			Object.keys(_this.queryModule).forEach(
					key => (_this.queryModule[key] = '')
			)
			_this.queryModule['timeCond'] = null
			_this.queryModule['projectId'] = _this.parentPagePara.projectId
			_this.queryModule['page'] = 1
			_this.workList()
		},
		queryRes(data) {
			const that = this

			that.queryModule = data
			that.workList()
		},
		workList() {
			const that = this

			this.$axios
					.POST('/api/v1/core/project/work/all', {
						page: that.queryModule.page - 1,
						size: that.pageSize,
						timeCond: that.queryModule.timeCond,
						workName: that.queryModule.workName,
						projectId: that.parentPagePara.projectId,
						createBy: ''
					})
					.then(res => {
						that.loading = false
						let data = res

						if (data.code == 200) {
							that.tableData = data.data.content
							that.total = data.data.totalElements
							for (let i = 0; i < that.tableData.length; i++) {
								if (that.tableData[i].isRun == true) {
									that.tableData[i].isRun = '运行中'
								} else {
									that.tableData[i].isRun = '未运行'
								}
								that.tableData[i]['Num'] =
										that.tableData[i].totalNum +
										'/' +
										that.tableData[i].sucNum +
										'/' +
										that.tableData[i].errNum +
										'/' +
										that.tableData[i].runNum
							}
						}
					})
		},

		handleCurrentChange(page) {
			this.queryModule.page = page
			this.workList()
		},

		reportDialog(row) {
			this.dialogReport = true
			this.report.workId = row.workId
		},
		goBack() {
			this.$router.push({name: 'reportExport'})
		},
		exportWork() {
			const that = this

			that.$axios
					.DOWNLOAD(
							'/api/v1/core/project/exportJobReport/' +
							this.parentPagePara.projectId,
							{
								workId: this.report.workId,
								isShowNotSuc: that.report.isShowNotSuc
							}
					)
					.then(res => {
						this.dialogReport = false
						const content = res

						that.$publics.downloadFile(content, '项目报告', 'xlsx')
					})
		},
		evaluationReport() {
			const that = this

			that.$axios
					.DOWNLOAD('/api/v1/core/project/exportJobAssessReport', null)
					.then(res => {
						const content = res

						that.$publics.downloadFile(content, '评估报告', 'pdf')
					})
		}
	}
}
</script>

<style></style>
